import '../../assets/css/custom.css';
import Footer from '../HeaderFooter/footer';
import Header from '../HeaderFooter/header';


import posterWebImg from '../../assets/img/blog/blog-main-poster-web.png';
import posteriPadImg from '../../assets/img/blog/blog-main-poster-ipad.png';
import posterMobImg from '../../assets/img/blog/blog-main-poster-mob.png';


import Blog1 from '../../assets/img/blog/blog-1.png';
import Blog2 from '../../assets/img/blog/blog-2.png';
import Blog3 from '../../assets/img/blog/blog-3.png';
import ArrowRightBlue from '../../assets/img/pg-about-us-arrow-right-blue.png';

import { useHistory } from 'react-router-dom';


const BlogMain = () => {

    const history = useHistory();

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onBlog1 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-1');
    }

    const onBlog2 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-2');
    }

    const onBlog3 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-3');
    }


    return (
        <>
            <Header />


            <div className='content-wrap'>

                {/* WEB */}
                <div className='prodect-devlopment-section-1-container d-none d-lg-block'>
                    <img src={posterWebImg} alt='...' className='w-100 prodect-devlopment-section-1-image h-50' />
                    <div className='container'>
                        <div className='prodect-devlopment-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>Blog</p>
                                {/* <h4>Your Product's Journey Starts Here</h4> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* iPad */}
                <div className='prodect-devlopment-section-1-container d-none d-md-block d-lg-none'>
                    <img src={posteriPadImg} alt='...' className='w-100 prodect-devlopment-section-1-image-ipad' />
                    <div className='container'>
                        <div className='prodect-devlopment-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>Blog</p>
                                {/* <h4>Your Product's Journey Starts Here</h4> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className='prodect-devlopment-section-1-container d-block d-sm-none'>
                    <img src={posterMobImg} alt='...' className='w-100 prodect-devlopment-section-1-image' />
                    <div className='prodect-devlopment-section-1-text-block p-4 '>
                        <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                            <p>Blog</p>
                            {/* <h4>At TASS, we are the doers! Turning ideas into tangible products and software gives us a certonin push!</h4> */}
                        </div>
                    </div>
                </div>

                <div className='about-us-section-5-container'>
                    <div className='container py-5'>
                        <div className='row about-us-section-5-all-cards py-3'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog1} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h5>
                                        <p className="about-us-section-5-card-text">India, a land of vibrant colors, rich history, diverse cultures, and breathtaking landscapes...</p>
                                        <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog2} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h5>
                                        <p className="about-us-section-5-card-text">In the digital age, Artificial Intelligence (AI) has become a driving force behind transformative...</p>
                                        <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog3} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h5>
                                        <p className="about-us-section-5-card-text">Traveling to India is an exhilarating experience that offers a tapestry of diverse cultures...</p>
                                        <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <Footer display={true}/>
        </>

    );
};

export default BlogMain;