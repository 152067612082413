import '../../assets/css/custom.css';
import Footer from '../HeaderFooter/footer';
import Header from '../HeaderFooter/header';



import poster1Blog1 from '../../assets/img/blog/blog1/main-web.png';

import img1 from '../../assets/img/blog/blog1/item1.png';
import img2 from '../../assets/img/blog/blog1/item2.png';
import img3 from '../../assets/img/blog/blog1/item3.png';
import img4 from '../../assets/img/blog/blog1/item4.png';
import img5 from '../../assets/img/blog/blog1/item5.png';
import img6 from '../../assets/img/blog/blog1/item6.png';
import img7 from '../../assets/img/blog/blog1/item7.png';
import img8 from '../../assets/img/blog/blog1/item8.png';
import img9 from '../../assets/img/blog/blog1/item9.png';
import img10 from '../../assets/img/blog/blog1/item10.png';


import conclusionSvg from '../../assets/img/blog/blog1/conclusion-icon.svg';

import Blog1 from '../../assets/img/blog/blog-1.png';
import Blog2 from '../../assets/img/blog/blog-2.png';
import Blog3 from '../../assets/img/blog/blog-3.png';
import ArrowRightBlue from '../../assets/img/pg-about-us-arrow-right-blue.png';
import { useHistory } from 'react-router-dom';

const BlogPage1 = () => {

    const history = useHistory();

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onBlog = (e) => {
        e.preventDefault();
        history.push('/blog');
    }

    const onBlog1 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-1');
    }

    const onBlog2 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-2');
    }

    const onBlog3 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-3');
    }

    let productData = [
        { title: "Jaipur - The Pink City", desc: "Immerse yourself in the royal heritage of Rajasthan as you explore the magnificent palaces and forts of Jaipur. From the iconic Hawa Mahal to the grandeur of Amber Fort, the Pink City is a cultural delight.", img: img1 },
        { title: "Varanasi - The Spiritual Capital", desc: "Step into the spiritual heart of India along the banks of the Ganges in Varanasi. Witness mesmerizing Ganga Aarti, stroll through narrow lanes, and experience the ancient rituals that make this city a soul-stirring destination.", img: img2 },
        { title: "Rishikesh and Haridwar - Gateway to the Himalayas", desc: "Immerse yourself in the royal heritage of Rajasthan as you explore the magnificent palaces and forts of Jaipur. From the iconic Hawa Mahal to the grandeur of Amber Fort, the Pink City is a cultural delight.", img: img3 },
        { title: "Goa - Sun, Sand, and Sea", desc: "Escape to the beaches of Goa for a laid-back vacation. Whether you seek vibrant nightlife, water sports, or serene sunsets, Goa caters to every traveler. Explore the Portuguese architecture, indulge in local cuisine, and dance to the rhythm of the samba.", img: img4 },
        { title: "Kerala - God's Own Country", desc: "Experience the lush green landscapes, backwaters, and serene beaches of Kerala. From the tranquil houseboat rides in Alleppey to the spice plantations in Munnar, every corner of this southern gem is a picturesque escape.", img: img5 },
        { title: "Agra - Home of the Taj Mahal", desc: "No list of Indian travel destinations is complete without mentioning the iconic Taj Mahal in Agra. A symbol of eternal love, this UNESCO World Heritage Site attracts millions with its stunning architecture and romantic history.", img: img6 },
        { title: "Leh-Ladakh - Land of High Passes", desc: "For adventure enthusiasts, Leh-Ladakh offers a surreal landscape of high mountains, deep valleys, and clear lakes. The monasteries, prayer flags, and the magnetic hill add to the mystique of this Himalayan region.", img: img7 },
        { title: "Mumbai - The City of Dreams", desc: "Explore the bustling metropolis of Mumbai, where tradition meets modernity. From the historic Gateway of India to the glamorous Bollywood scene, Mumbai is a melting pot of cultures and aspirations.", img: img8 },
        { title: "Khajuraho - Temples of Love", desc: "Discover the intricately carved temples of Khajuraho, a UNESCO World Heritage Site. These ancient structures are famous for their stunning erotic sculptures and intricate architecture, offering a glimpse into India's artistic past.", img: img9 },
        { title: "Andaman and Nicobar Islands - Tropical Paradise", desc: "Escape to the pristine beaches and crystal-clear waters of the Andaman and Nicobar Islands. Snorkel in the coral reefs, relax on the sun-kissed beaches, and explore the untouched beauty of this tropical archipelago.", img: img10 }

    ]


    return (
        <>
            <Header />

            <div class="sticky-social">
                <ul class="social">
                    <li class="fb"><a href="#"><i class="fa-brands fa-facebook-f" aria-hidden="true"></i></a></li>
                    <li class="twitter"><a href="#"><i class="fa-brands fa-x-twitter" aria-hidden="true"></i></a></li>
                    <li class="insta"><a href="#"><i class="fa-brands fa-linkedin-in" aria-hidden="true"></i></a></li>
                </ul>
            </div>


            <div className='content-wrap'>
                <div className='blog-page-section-1'>
                    <div className='container'>
                        <hr className='mt-3' />
                        {/* <button className='btn btn-info mt-3' onClick={onBlog}>Blogs</button> */}
                        <div className='row py-3'>
                            <h1>Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h1>
                        </div>
                        <div className='row py-3'>
                            <h2>December 19, 2023 &nbsp;  &nbsp;|   &nbsp; &nbsp;13Min Read</h2>
                        </div>
                        <div className='row py-3'>
                            <img src={poster1Blog1} alt='...' />
                        </div>
                        <div className='row pt-5'>
                            <h3>Introduction</h3>
                        </div>
                        <div className='row'>
                            <h4>India, a land of vibrant colors, rich history, diverse cultures, and breathtaking landscapes, is a treasure trove of travel experiences. From the majestic Himalayas to the serene beaches of the south, every corner of the country offers a unique adventure. In this blog, we'll take you on a virtual tour of the top 10 travel destinations in India that showcase the country's incredible diversity and beauty.</h4>
                        </div>
                    </div>
                </div>

                <div className='prodect-devlopment-section-2-container pb-3 pt-3'>
                    <div className='container'>


                        <div className='row'>
                            {
                                productData.map((d) => <div className='col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <div className='blog-item-card'>
                                        <img src={d.img} className="blog-item-img" alt="..." />
                                        <h1 class="title">{d.title}</h1>
                                        <p class="blog-item-card-desc">{d.desc}</p>
                                    </div>
                                </div>
                                )
                            }
                        </div>

                    </div>
                </div>

                {/* Conclusion */}
                <div className='blog-page-conslusion'>
                    <div class="container">
                        <div className='row py-3'>
                            <hr className='mx-3' />
                            <h1 className='pt-3'>Conclusion</h1>
                        </div>
                        <div className='row pb-3 '>
                            <p>India, with its myriad landscapes and cultural richness, has something for every type of traveler. Whether you're seeking spirituality, adventure, or relaxation, these top 10 travel destinations offer a glimpse into the diverse tapestry of this incredible country. So, pack your bags, embark on a journey, and let India enchant you with its magic.</p>
                        </div>
                        {/* <div className='d-flex'>
                            <div className='me-3'>
                                <img src={conclusionSvg} className="" alt="..." />
                            </div>
                            <div className='col-11'>
                                <div className='row'>
                                    <h3>Shayan B</h3>
                                </div>
                                <div className='row'>
                                    <h4>Technology Lead</h4>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='about-us-section-5-container'>
                    <div className='container py-5'>
                        <div className='row'>
                            <h1 className='heading-blog-page'>Related Post</h1>
                        </div>
                        <div className='row about-us-section-5-all-cards py-3'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog1} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h5>
                                        <p className="about-us-section-5-card-text">India, a land of vibrant colors, rich history, diverse cultures, and breathtaking landscapes...</p>
                                        <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog2} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h5>
                                        <p className="about-us-section-5-card-text">In the digital age, Artificial Intelligence (AI) has become a driving force behind transformative...</p>
                                        <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog3} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h5>
                                        <p className="about-us-section-5-card-text">Traveling to India is an exhilarating experience that offers a tapestry of diverse cultures...</p>
                                        <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <Footer />
        </>

    );
};

export default BlogPage1;