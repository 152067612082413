import '../../assets/css/custom.css';
import Footer from '../HeaderFooter/footer';
import Header from '../HeaderFooter/header';



import poster1Blog3 from '../../assets/img/blog/blog3/blog-main-3.png';




import conclusionSvg from '../../assets/img/blog/blog1/conclusion-icon.svg';

import Blog1 from '../../assets/img/blog/blog-1.png';
import Blog2 from '../../assets/img/blog/blog-2.png';
import Blog3 from '../../assets/img/blog/blog-3.png';
import ArrowRightBlue from '../../assets/img/pg-about-us-arrow-right-blue.png';
import { useHistory } from 'react-router-dom';

const BlogPage3 = () => {

    const history = useHistory();

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onBlog = (e) => {
        e.preventDefault();
        history.push('/blog');
    }

    const onBlog1 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-1');
    }

    const onBlog2 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-2');
    }

    const onBlog3 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog-3');
    }

    let productData = [
        { title: "Respect Local Customs and Traditions", desc: "India is known for its cultural diversity, and each region has its own set of customs and traditions. Respect local norms, dress modestly, and be mindful of religious practices. Learning a few basic phrases in the local language can go a long way in fostering positive interactions." },
        { title: "Health Precautions", desc: "Prioritize your health by staying hydrated, especially in warmer regions. Carry a basic first aid kit, and be cautious with street food to avoid stomach issues. Consult a healthcare professional before your trip to ensure you have the necessary vaccinations and medications." },
        { title: "Chatbots for Instant Assistance", desc: "Chatbots powered by AI have become invaluable travel companions. These virtual assistants provide real-time assistance, helping travelers with everything from booking accommodations and flights to providing local information and tips. Chatbots are available 24/7, offering immediate support and enhancing the overall travel experience." },
        { title: "Currency and Payment Methods", desc: "While credit cards are widely accepted in urban areas, it's advisable to carry some local currency, especially in more remote locations. Inform your bank about your travel dates to avoid any issues with card transactions, and be aware of currency exchange rates." },
        { title: "Transportation Planning", desc: "India's transportation system can be overwhelming, but proper planning can make it more manageable. Book transportation in advance, be aware of travel times, and consider using reputable transportation services. In cities, ride-sharing apps are often a convenient and safe option." },
        { title: "Bargaining Skills", desc: "Bargaining is a common practice in local markets, and it's expected in many situations. Polite negotiation can lead to fair prices for goods and services. However, be mindful not to engage in aggressive or disrespectful haggling." },
        { title: "Weather Considerations", desc: "India experiences diverse climatic conditions, so check the weather of your destination before packing. Whether you're visiting the desert state of Rajasthan or the Himalayan region, having appropriate clothing for the climate is essential for a comfortable trip." },
        { title: "Stay Connected", desc: "Ensure you have a local SIM card or an international roaming plan to stay connected during your travels. Having a reliable means of communication is important for emergencies and navigation." },
        { title: "Cuisine Exploration", desc: "Indian cuisine is renowned for its flavors, and trying local dishes is a must. However, be cautious about street food, especially if you have a sensitive stomach. Choose reputable restaurants and indulge in the diverse culinary delights." },
        { title: "Cultural Sensitivity in Photography", desc: "Seek permission before photographing people, especially in rural areas. In some places, taking pictures may be considered intrusive or disrespectful. It's always best to ask and respect the wishes of the locals." },
        { title: "Safety Awareness", desc: "While India is generally safe for travelers, it's essential to stay vigilant. Be cautious with your belongings, avoid poorly lit areas at night, and stay informed about the safety situation in the regions you plan to visit. Trust your instincts and take necessary precautions to ensure your well-being." }
    ]


    return (
        <>
            <Header />

            <div class="sticky-social">
                <ul class="social">
                    <li class="fb"><a href="#"><i class="fa-brands fa-facebook-f" aria-hidden="true"></i></a></li>
                    <li class="twitter"><a href="#"><i class="fa-brands fa-x-twitter" aria-hidden="true"></i></a></li>
                    <li class="insta"><a href="#"><i class="fa-brands fa-linkedin-in" aria-hidden="true"></i></a></li>
                </ul>
            </div>

            <div className='content-wrap'>
                <div className='blog-page-section-1'>
                    <div className='container'>
                        <hr className='mt-3' />
                        {/* <button className='btn btn-info mt-3' onClick={onBlog}>Blogs</button> */}
                        <div className='row py-3'>
                            <h1>Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h1>
                        </div>
                        <div className='row py-3'>
                            <h2>By <b>Shayan B</b> &nbsp;  &nbsp; | &nbsp;  &nbsp; December 19, 2023 &nbsp;  &nbsp; | &nbsp;  &nbsp; 13Min Read</h2>
                        </div>
                        <div className='row py-3'>
                            <img src={poster1Blog3} alt='...' />
                        </div>
                        <div className='row pt-5'>
                            <h3>Introduction</h3>
                        </div>
                        <div className='row'>
                            <h4>Traveling to India is an exhilarating experience that offers a tapestry of diverse cultures, breathtaking landscapes, and a rich historical heritage. As you embark on your Indian adventure, it's crucial to keep in mind certain essentials to ensure a smooth and enjoyable journey. In this blog post, we'll explore the must-useful things to consider while traveling in India.</h4>
                        </div>
                    </div>
                </div>

                <div className='prodect-devlopment-section-2-container pb-3 pt-3'>
                    <div className='container'>
                        <div className='row'>
                            {
                                productData.map((d) => <div className='col-12 mb-4'>
                                    <div className='blog-item-2'>
                                        <h1 class="title">{d.title}</h1>
                                        <p class="desc">{d.desc}</p>
                                    </div>
                                </div>
                                )
                            }
                        </div>

                    </div>
                </div>

                {/* Conclusion */}
                <div className='blog-page-conslusion pb-4'>
                    <div class="container">
                        <hr className='mx-3' />

                        <div className='row py-3'>
                            <h1>Conclusion</h1>
                        </div>
                        <div className='row py-3'>
                            <p>India, with its myriad landscapes and cultural richness, has something for every type of traveler. Whether you're seeking spirituality, adventure, or relaxation, these top 10 travel destinations offer a glimpse into the diverse tapestry of this incredible country. So, pack your bags, embark on a journey, and let India enchant you with its magic.</p>
                        </div>
                        {/* <div className='d-flex'>
                            <div className='me-3'>
                                <img src={conclusionSvg} className="" alt="..." />
                            </div>
                            <div className=''>
                                <div className='row'>
                                    <h3>Shayan B</h3>
                                </div>
                                <div className='row'>
                                    <h4>Technology Lead</h4>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='about-us-section-5-container'>
                    <div className='container py-5'>
                        <div className='row'>
                            <h1 className='heading-blog-page'>Related Post</h1>
                        </div>
                        <div className='row about-us-section-5-all-cards py-3'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog1} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h5>
                                        <p className="about-us-section-5-card-text">India, a land of vibrant colors, rich history, diverse cultures, and breathtaking landscapes...</p>
                                        <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog2} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h5>
                                        <p className="about-us-section-5-card-text">In the digital age, Artificial Intelligence (AI) has become a driving force behind transformative...</p>
                                        <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog3} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h5>
                                        <p className="about-us-section-5-card-text">Traveling to India is an exhilarating experience that offers a tapestry of diverse cultures...</p>
                                        <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <Footer />
        </>

    );
};

export default BlogPage3;