
import React from 'react';

function BackToTop() {

  const [position, setPosition] = React.useState({ top: 0, left: 0 })
  React.useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: 'smooth'
    })
  })

  const scrollTop = React.useRef()
  React.useEffect(() => {
    window.addEventListener('scroll', (e) => {
      window.scrollY > 200
        ? scrollTop.current.style.display = 'inline-block'
        : scrollTop.current.style.display = 'none'
    })
  })

  return (
    <span
      onClick={() => setPosition({ ...position, position: { top: 0, left: 0 } })}
      className="back-to-top"
      ref={scrollTop}
    > <i className="fa fa-arrow-up"></i>
    </span>
  )
}

export default BackToTop;