import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BackToTop from './component/backToTop/backToTop';
import Home from './component/home';
import ContactUs from "./component/contactUs";
import AboutUs from './component/aboutUs';
import ProductDevelopment from './component/productDevelopment';
import BlogMain from './component/Blog/blogMain';
import BlogPage1 from './component/Blog/blogPage1';
import BlogPage2 from './component/Blog/blogPage2';
import BlogPage3 from './component/Blog/blogPage3';


function App() {
  return (
    <>
      <BackToTop />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/product-development" component={ProductDevelopment} />
          <Route exact path="/blog" component={BlogMain} />
          <Route exact path="/blog-1" component={BlogPage1} />
          <Route exact path="/blog-2" component={BlogPage2} />
          <Route exact path="/blog-3" component={BlogPage3} />
          <Route exact path="*" component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
